import React, {FunctionComponent} from 'react';
import {graphql, PageProps} from 'gatsby';

import {Box} from '@mui/material';
import Layout from '../../components/layout/Layout';

type DataType = {
    wpPost: {
        title: string;
        content: string;
    };
};

const PostPage: FunctionComponent<PageProps<DataType>> = ({
    data
}) => {
    return <Layout title={data.wpPost.title}>
        <Box dangerouslySetInnerHTML={{
            __html: data.wpPost.content,
        }} sx={{
            '& .wp-block-column > p': {
                fontSize: '0.875rem',
                color: '#9a9a9a',
                letterSpacing: '0.5px',
                my: '10px',
            }
        }} />
    </Layout>;
};

export const pageQuery = graphql`
    query PostDetails($postSlug: String) {
        wpPost(slug: {eq: $postSlug}) {
            title
            content
        }
    }
`;

export default PostPage;
